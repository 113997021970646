/*******************/
/*                 */
/* Schulkinder App */
/*                 */
/*******************/

/***********************************************************/

/* Fonts */


@font-face {
    font-family: 'Rubik-Regular';
    src: url('./fonts/Rubik-Regular/Rubik-Regular.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Rubik-Regular/Rubik-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Rubik-Regular/Rubik-Regular.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Rubik-Regular/Rubik-Regular.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Rubik-Regular/Rubik-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Rubik-Regular/Rubik-Regular.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-Italic';
    src: url('./fonts/Rubik-Italic/Rubik-Italic.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Rubik-Italic/Rubik-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Rubik-Italic/Rubik-Italic.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Rubik-Italic/Rubik-Italic.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Rubik-Italic/Rubik-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Rubik-Italic/Rubik-Italic.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-Black';
    src: url('./fonts/Rubik-Black/Rubik-Black.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Rubik-Black/Rubik-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Rubik-Black/Rubik-Black.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Rubik-Black/Rubik-Black.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Rubik-Black/Rubik-Black.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Rubik-Black/Rubik-Black.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-BlackItalic';
    src: url('./fonts/Rubik-BlackItalic/Rubik-BlackItalic.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Rubik-BlackItalic/Rubik-BlackItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Rubik-BlackItalic/Rubik-BlackItalic.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Rubik-BlackItalic/Rubik-BlackItalic.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Rubik-BlackItalic/Rubik-BlackItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Rubik-BlackItalic/Rubik-BlackItalic.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-Bold';
    src: url('./fonts/Rubik-Bold/Rubik-Bold.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Rubik-Bold/Rubik-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Rubik-Bold/Rubik-Bold.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Rubik-Bold/Rubik-Bold.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Rubik-Bold/Rubik-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Rubik-Bold/Rubik-Bold.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-BoldItalic';
    src: url('./fonts/Rubik-BoldItalic/Rubik-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Rubik-BoldItalic/Rubik-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Rubik-BoldItalic/Rubik-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Rubik-BoldItalic/Rubik-BoldItalic.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Rubik-BoldItalic/Rubik-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Rubik-BoldItalic/Rubik-BoldItalic.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-Light';
    src: url('./fonts/Rubik-Light/Rubik-Light.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Rubik-Light/Rubik-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Rubik-Light/Rubik-Light.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Rubik-Light/Rubik-Light.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Rubik-Light/Rubik-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Rubik-Light/Rubik-Light.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-LightItalic';
    src: url('./fonts/Rubik-LightItalic/Rubik-LightItalic.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Rubik-LightItalic/Rubik-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Rubik-LightItalic/Rubik-LightItalic.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Rubik-LightItalic/Rubik-LightItalic.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Rubik-LightItalic/Rubik-LightItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Rubik-LightItalic/Rubik-LightItalic.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-Medium';
    src: url('./fonts/Rubik-Medium/Rubik-Medium.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Rubik-Medium/Rubik-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Rubik-Medium/Rubik-Medium.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Rubik-Medium/Rubik-Medium.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Rubik-Medium/Rubik-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Rubik-Medium/Rubik-Medium.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-MediumItalic';
    src: url('./fonts/Rubik-MediumItalic/Rubik-MediumItalic.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Rubik-MediumItalic/Rubik-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/Rubik-MediumItalic/Rubik-MediumItalic.woff2') format('woff2'), /* Modern Browsers */
    url('./fonts/Rubik-MediumItalic/Rubik-MediumItalic.woff') format('woff'), /* Modern Browsers */
    url('./fonts/Rubik-MediumItalic/Rubik-MediumItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/Rubik-MediumItalic/Rubik-MediumItalic.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

/***********************************************************/

/* Allgemein */


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Rubik-Regular, sans-serif;
    color: #35383c;
    min-height: 100vh;
}

h1 {
    font-family: Rubik-Bold, sans-serif;
    font-size: 1.75rem;
    text-align: center;
}

h2 {
    font-family: Rubik-Bold, sans-serif;
    font-size: 1rem;
    text-align: center;
}

h3 {
    font-size: 0.85rem;
    font-weight: normal;
}

p {
    text-align: center;
}

a {
    text-decoration: none;
    outline: none;
    color: #35383c;
}

input {
    display: block;
    font-size: 1rem;
    background-color: #f5f5f5;
    border: none;
}

label {
    font-family: Rubik-Bold, sans-serif;
    display: block;
}



/***********************************************************/

/* header */

header {
    background-color: #f5f5f5;
    height: 6rem;
    position: sticky;
    top: 0;
    z-index: 1;
    font-size: 0.75rem;
}

header>a>img {
    float: left;
    margin: 2rem;
    background-color: #9cc2df;
    color: white;
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    font-size: 2rem;
    line-height: 1.8rem;
    cursor: pointer;
    text-align: center;
}

header>a>img:hover {
    background-color: #90b6d2;
}

header div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-width: calc(100% - 6rem);
    height: 6rem;
}

header div p,
header div img {
    margin-right: 2rem;
}

header div img {
    max-width: 10rem;
}

header>a>img.success {
    transform: rotate(0deg);
    line-height: 2rem;
    background-color: #9a9b9d;
}

/***********************************************************/

/* main */


main {
    position: relative;
    height: calc(100vh - 6rem);
}

main img {
    display: block;
    text-align: center;
}

main .content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.button,
.line_button {
    font-size: 1rem;
    display: block;
    width: 75%;
    margin: 0 auto;
    color: white;
    line-height: 2rem;
    border: none;
    border-radius: 1.25rem;
    background-color: #9cc2df;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover,
.line_button:hover {
    background-color: #90b6d2;
    transition: background-color 0.3s;
}

.button[disabled] {
    background-color: #9a9b9d;
    cursor: initial;
}

/***********************************************************/

/* Passagierliste */


#passenger_screen .passenger_content {
    padding: 4rem 5% 0 5%;
}

.list {
    display: grid;
    grid-template-columns: 70% 15% 15%;
    margin: 3rem 0;
}

.list h3,
.list p,
.list span {
    margin: 0.75rem 0;
}

.list h3 {
    hyphens: auto;
    text-align: center;
}

.list h3:nth-of-type(2) {
    font-weight: bold;
}

.list h3:first-of-type,
.list p {
    text-align: left;
}

.list p[data-preselect=true] {
    color: #9a9b9d;
}

.list p span {
    display: block;
    font-size: 0.75rem;
    margin: 0;
}

.list label input[type="radio"] {
    display: none;
}

.list label input[value="false"] + span {
    background-color: #35383c;
    transition: background-color 0.3s;
    color: #35383c;
}

.list label input[value="false"] + span:hover {
    background-color: #000000 !important;
    transition: background-color 0.3s;
    cursor: pointer;
}

.list label input[value="true"] + span {
    background-color: #9cc2df;
    transition: background-color 0.3s;
    color: #9cc2df;
}

.list label input[value="true"] + span:hover {
    background-color: #90b6d2 !important;
    transition: background-color 0.3s;
    cursor: pointer;
}

.list label span {
    display: block;
    background-color: #9cc2df;
    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
    margin: 0 auto;
    text-align: center;
    line-height: 2rem;
    font-size: 2rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.list label input[type="radio"] + span img {
    display: none;
}

.list label input[type="radio"]:checked + span img {
    display: block;
    color: white;
}

.list label span img.list_check {
    width: 1.3rem;
    margin: auto;
    padding-top: 0.5rem;
    text-indent: -0.1rem;
    line-height: 1.1rem;
}

.list label span img.list_cross {
    width: 1.15rem;
    margin: auto;
    padding-top: 0.42rem;
    text-indent: -0.25rem;
    line-height: 1.25rem;
}

.list .classnumber {
    display: inline;
    font-size: 0.75rem;
}

.list_submit {
    position: sticky;
    bottom: 0;
    background-color: white;
    margin: 2rem calc(2rem - 5%);
    padding: 2rem;
}

.list_submit .button {
    grid-column: 1 / 4;
    width: 100%;
}

/***********************************************************/

/* Buslinien */


.line_content {
    padding: 4rem 3rem;
}

.line_content button {
    margin: 1.5rem auto 0.75rem auto;
    max-width: 20rem;
}

.line_content p {
    width: 75%;
    margin: 0 auto;
    max-width: 20rem;
}

.line_content p:first-of-type {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 4rem;
}

.line_content .line_switch {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.line_content .line_switch>div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 1.5rem;
}

.line_content .line_switch p {
    line-height: 2.75rem;
    margin: 0 1rem;
}

.line_content .line_switch label {
    height: 2.75rem;
}

.line_switch label {
    height: 2.75rem;
}

.line_switch label input[type="radio"] {
    display: none;
}

.line_switch label span {
    display: inline-block;
    background-color: #9cc2df;
    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
    margin: 0 auto;
    text-align: center;
    line-height: 2rem;
    font-size: 2rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.3s;
}

.line_switch label input + span:hover {
    background-color: #90b6d2 !important;
    transition: background-color 0.3s;
    cursor: pointer;
}

.line_switch label input[type="radio"] + span img {
    display: none;
}

.line_switch label input[type="radio"]:checked + span img {
    display: inline-block;
    color: white;
    vertical-align: super;
}

.line_switch label span img.list_check {
    width: 1.3rem;
    margin: auto;
    padding-top: 0.5rem;
    text-indent: -0.1rem;
    line-height: 1.1rem;
}

.line_switch label span img.list_cross {
    width: 1.15rem;
    margin: auto;
    padding-top: 0.42rem;
    text-indent: -0.25rem;
    line-height: 1.25rem;
}

@media screen and (max-width: 36rem) {
    .line_content {
        padding: 2rem 1rem;
    }

    .line_content button {
        width: 80%;
    }

    .line_content p {
        width: 80%;
    }

    .line_content p:first-of-type {
        margin-bottom: 3rem;
    }
}

/***********************************************************/

/* Organisation */


#organisation_screen h2 {
    padding-top: 5em;
}

#organisation_screen .content {
    top: calc(50% + 3em);
}

#organisation_screen .content img {
    margin: 5rem auto;
    width: calc(100% - 10rem);
    max-width: 30rem;
}

/***********************************************************/

/* Login Screen */


#login_screen h1 {
    padding-top: 3em;
}

#login_screen>div {
    position: relative;
    height: calc(100vh - 12rem);
}

#login {
    width: 60%;
    margin: 0 auto;
}

#login label {
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

#login #loginbutton {
    width: 100%;
    margin-top: 2.5rem;
    padding: 0;
}

#login input {
    width: 100%;
    padding: 0.5rem;
}

@media screen and (max-width: 36rem) {
    #login {
        box-sizing: border-box;
        width: 100%;
        padding: 0 1rem;
    }
}


/***********************************************************/

/* Welcome Screen */


#welcome_screen {
    height: 100vh;
}

#welcome_screen h1 {
    padding: 2em;

}

#welcome_screen img {
    margin: auto;
}

/***********************************************************/

/* Logout Screen */


#logout_screen h1 {
    padding-top: 3em;
}

#logout_screen>div{
    position: relative;
    height: calc(100vh - 8rem);
}

#logout_screen .content a button {
    margin: 3rem auto;
    max-width: 20rem;
}

#logout_screen .content a:nth-of-type(2) button{
    background-color: #35383c;
}

#logout_screen .content a:nth-of-type(2) button:hover {
    background-color: #000000;
    transition: background-color 0.3s;
}


/***********************************************************/

/* Success Screen */


#success_screen {
    height: calc(100vh - 8rem);
    margin-top: 2rem;
    display: grid;
    grid-template-rows: 20% 20% 55%;
    align-items: center;
    padding: 1rem;
}

#success_screen img {
    margin: auto;
}

#success_screen .hide {
    display: none;
}


/***********************************************************/

/* Screen Visibility*/

#header {
    display: none;
}